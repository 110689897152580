



























































































































.create-organization {
  margin-bottom: 50px;
}
.organizations {
  &-body {
    margin-top: 30px;
    padding: 0 16px;

  }
}
